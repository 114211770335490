import React, { memo, useCallback, useMemo } from 'react'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'
import Container from '@mui/material/Container'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import usePageProps from 'hooks/usePageProps'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import YoutubeIcon from '@mui/icons-material/YouTube'
import TwitterIcon from '@mui/icons-material/Twitter'
import TiktokIcon from 'public/svg/tiktok.svg'
import AMVOIcon from 'public/img/afiliado-amvo-blanco.png'
import useUtms from 'hooks/useUtms'
import { titleCaps } from 'utils/universal'
import { setOverridedFilters } from 'redux/modules/search'
import { useDispatch } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'
import Text, { TEXT_TYPES } from 'components/designSystem/Text'
import { parseFiltersToUrl } from 'filters'
import sx from './styles'

const Footer = (props) => {
  const {
    logo = '',
    logoAltText = '',
    mainMenu = [],
    menu = [],
    makes = [],
    variantPhone,
  } = props
  const {
    siteName, socialLinks, shoppingPhone, salesPhone,
  } = usePageProps()
  const router = useRouter()
  const query = useUtms()
  const dispatch = useDispatch()
  const { formatMessage: f } = useIntl()
  const basePath = f({ id: 'ROUTE_SEARCH' })
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))
  const isTablet = useMediaQuery((theme) => theme.breakpoints.down('md'))
  const phone = useMemo(() => (variantPhone === 'sales' ? salesPhone : shoppingPhone), [variantPhone])

  const handleRedirectMaker = useCallback((event, maker) => {
    event.preventDefault()
    dispatch(setOverridedFilters({ maker: maker.toLowerCase() }))
    if (router.asPath.includes(f({ id: 'ROUTE_SEARCH' }))) {
      router.push(`/autos-seminuevos/marca-${maker.toLowerCase()}`, null, { shallow: true })
    } else {
      router.push(`/autos-seminuevos/marca-${maker.toLowerCase()}`)
    }
  }, [])

  const makesList = useMemo(() => {
    return makes.map((maker, i) => (
      <Button
        key={`footerlink-${maker.name}`}
        href={`${basePath}${(parseFiltersToUrl({ maker: maker.name })).toLowerCase()}`}
        onClick={(e) => handleRedirectMaker(e, maker.name)}
        variant="text"
        display="inline"
        sx={sx.carList}
        component="a"
        title={`Autos seminuevos ${maker.name}`}
      >
        {i !== 0 && ' | '}
        {titleCaps(maker.name)}
      </Button>
    ))
  }, [makes])

  return (
    <Box
      sx={sx.root}
      id="Footer"
    >
      <Container maxWidth="xl">
        <Grid
          container
          spacing={2}
        >
          <Grid
            item
            md={3}
            container
            spacing={2}
          >
            <Grid
              item
              sx={sx.logo}
            >
              {typeof logo === 'string' ? (
                <Image
                  src={logo}
                  alt={logoAltText}
                  width={200}
                  height={36}
                  layout="responsive"
                />
              ) : <logo />}
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Text
                type={TEXT_TYPES.strong}
                sx={sx.footerTitle}
              >
                <FormattedMessage id="FOOTER_LOGO_DESCRIPTION" />
              </Text>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Grid
                container
                spacing={2}
                justifyContent={isTablet ? 'flex-start' : 'space-between'}
              >
                <Grid item>
                  <a
                    href={socialLinks?.facebook}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Facebook"
                  >
                    <FacebookIcon sx={{ color: 'white' }} />
                  </a>
                </Grid>

                <Grid item>
                  <a
                    href={socialLinks?.linkedin}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Linkedin"
                  >
                    <LinkedInIcon sx={{ color: 'white' }} />
                  </a>
                </Grid>

                <Grid item>
                  <a
                    href={socialLinks?.instagram}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Instagram"
                  >
                    <InstagramIcon sx={{ color: 'white' }} />
                  </a>
                </Grid>

                <Grid item>
                  <a
                    href={socialLinks?.youtube}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Youtube"
                  >
                    <YoutubeIcon sx={{ color: 'white' }} />
                  </a>
                </Grid>

                <Grid item>
                  <a
                    href={socialLinks?.twitter}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Twitter"
                  >
                    <TwitterIcon sx={{ color: 'white' }} />
                  </a>
                </Grid>

                <Grid item>
                  <a
                    href={socialLinks?.tiktok}
                    target="_blank"
                    rel="noreferrer"
                    aria-label="Tiktok"
                  >
                    <TiktokIcon style={{ width: 20, height: 20, color: 'white' }} />
                  </a>
                </Grid>
                <Grid item>
                  <Box sx={{ width: 50, height: 20, position: 'relative' }}>
                    <Image
                      src={AMVOIcon}
                      width={50}
                      height={20}
                      layout="fill"
                      objectFit="contain"
                      alt="AMVO logo"
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

          </Grid>
          <Grid
            item
            xs={12}
            md={1}
            sx={{ display: 'flex', justifyContent: 'center' }}
          >
            <Divider
              sx={{
                backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.2)' : 'secondary.contrastText', width: isMobile ? '100%' : '1px', height: '90%',
              }}
              orientation={isMobile ? 'horizontal' : 'vertical'}
            />
          </Grid>
          <Grid
            item
            md={8}
            container
            spacing={2}
            component="ul"
          >
            {mainMenu
              .filter((i) => !i.hideInFooter)
              .map((item) => (
                <Grid
                  key={`section-${item.id}`}
                  item
                  xs={12}
                  sm={4}
                  md
                  component="li"
                  sx={sx.menuItem}
                >
                  <Box>
                    <Link
                      href={`${item.url}${query}`}
                      passHref
                    >
                      <Text
                        type={TEXT_TYPES.heading.h4}
                        component="a"
                        href={`${item.url}${query}`}
                        id={item.link_id}
                        sx={sx.linktTitle}
                        title={item?.titleFooter ? item.titleFooter : item?.alt}
                      >
                        {item.title}
                      </Text>
                    </Link>
                    {item.items && item.items.map((subItem) => {
                      return subItem.type === 'external' ? (
                        <Text
                          type={TEXT_TYPES.link.text}
                          sx={sx.linkItem}
                          title={subItem.title}
                          target="_blank"
                          href={subItem.url}
                          key={`items-${subItem.title}`}
                        >
                          {subItem.title}
                        </Text>
                      ) : (
                        <Link
                          key={subItem.id}
                          href={`${subItem.url}${query}`}
                          passHref
                          title={subItem.title}
                        >
                          <Text
                            type={TEXT_TYPES.link.text}
                            sx={sx.linkItem}
                            title={subItem.title}
                          >
                            {subItem.title}
                          </Text>
                        </Link>
                      )
                    })}
                  </Box>
                </Grid>
              ))}
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Divider sx={{ backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.2)' : 'secondary.contrastText' }} />
          </Grid>

          <Grid
            item
            md={12}
          >
            <Text
              type={TEXT_TYPES.body.x1}
              sx={sx.carsForSale}
            >
              <FormattedMessage id="PRODUCT_DESCRIPTION2" />
            </Text>

            <Box sx={{ lineHeight: '9px' }}>
              {makesList}
              {' | '}
              <Link
                href={f({ id: 'ROUTE_MAKES_SITEMAP' })}
              >
                <Button
                  variant="text"
                  display="inline"
                  sx={sx.carList}
                  aria-label="Sitemap"
                >
                  <FormattedMessage id="SITEMAP" />
                </Button>
              </Link>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
          >
            <Divider sx={{ backgroundColor: isMobile ? 'rgba(255, 255, 255, 0.2)' : 'secondary.contrastText' }} />
          </Grid>

          <Grid
            item
            md={6}
          >
            <Text
              type={TEXT_TYPES.body.x2}
              sx={sx.copyright}
            >
              &copy;
              {' '}
              {siteName}
              {' '}
              {new Date().getFullYear()}
              {' '}
              <FormattedMessage id="COPYRIGHT" />
            </Text>
            <Typography
              variant="body1"
              sx={sx.copyright}
            >
              <FormattedMessage id="ADDRESS" />
            </Typography>
            <a
              href="tel:5589571916"
              style={{ textDecorationColor: 'white' }}
            >
              <Typography
                variant="body1"
                sx={sx.copyright}
              >
                <FormattedMessage
                  id="PHONE_NUMBER"
                  values={{ phoneNumber: phone }}
                />
              </Typography>
            </a>
          </Grid>

          <Grid
            item
            md={6}
            container
            spacing={2}
            component="ul"
            sx={sx.rightSections}
          >
            {menu
              ?.filter((i) => i.title !== 'Prensa').map((item) => (
                <Grid
                  key={item.title}
                  item
                  component="li"
                  sx={sx.menuItem}
                  xs={12}
                  md="auto"
                >
                  <Link
                    href={item.url}
                    passHref
                    prefetch={!item.skipPrefetch}
                  >
                    <Text
                      type={TEXT_TYPES.link.text}
                      sx={sx.bottomLinks}
                    >
                      {item.title}
                    </Text>
                  </Link>
                </Grid>
              ))}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default memo(Footer)
